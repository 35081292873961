import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_green_rgb.svg";

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Cta,
  Disclaimer,
  Footer,
  Section,
  WhiteSection
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const StockDividend: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);






  const data = [
    {
      name: '2019',
      div: 3.1,
    },
    {
      name: '2020',
      div: 1.5,
    },
    {
      name: '2021',
      div: 3.25,
    },
    {
      name: '2022',
      div: 3.30,
    },
    {
      name: '2023',
      div: 4.10,
    },
    {
      name: '2024',
      div: 4.10,
    },
  ];
  const arrowBox = (num:number, title:string, text:string) => {
    return <>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          borderRadius: '10px',
          padding: 2.5,
          width: '100%',
          position: 'relative',
          textAlign: 'center',
          marginBottom: 5
        }}
      >
        <Typography variant="h2">{t("nw.stock.dividend.steps.step_name").replace(/<[^>]*>?/gm, '')} {num}</Typography>
      </Box>
      <Box sx={{
        position: 'relative',
        textAlign: 'center',
        width: '100%'
      }}>
        <Typography variant="h2" sx={{marginBottom: 2}}>{title}</Typography>
        {num < 3 && pointyRightArrow(90, num)}
      </Box>
      <Typography variant="subtitle1" sx={{textAlign: 'center'}}>{text}</Typography>
      {num < 3 && pointyBottomArrow(180, num)}
    </>;
  }
  const pointyBottomArrow = (deg:number, num:number) => {
    return <Box sx={{
      position: 'absolute',
      bottom: -24,
      left: "50%",
      display: num === 1 ? {
        xs: 'inline-block',
        md: 'none',
        lg: 'none'
      } : {
        xs: 'inline-block',
        md: 'inline-block',
        lg: 'none'
      },
      marginLeft: "-16px",
      "& svg": {
        transform: `rotate(${deg}deg)`,
        width: '32px'
      }
    }}><Arrow /></Box>
  }
  const pointyRightArrow = (deg:number, num:number) => {
    return <Box sx={{
      position: 'absolute',
      top: 0,
      right: -28,
      display: num === 1 ? {
        xs: 'none',
        md: 'inline-block',
        lg: 'inline-block'
      } : {
        xs: 'none',
        md: 'none',
        lg: 'inline-block'
      },
      "& svg": {
        transform: `rotate(${deg}deg)`,
        width: '32px'
      }
    }}><Arrow /></Box>
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h5" sx={{marginTop: 8}}>{t("nw.reclame")}</Typography>
          <Typography variant="h1" sx={{
            marginTop: 2,
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.stock.dividend.hero.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="subtitle1" html fuck_p_tags sx={{
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.stock.dividend.hero.subsection")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, marginBottom: 8}}>
          <DarkGreenButton
            label={t("nw.stock.transfer.hero.cta.faq")}
            id="sub_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
            }}
            path="/faq"
          />
        </Grid>
      </Grid>
    </WhiteSection>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6} lg={4} sx={{position: 'relative', paddingBottom: 4}}>
          {arrowBox(
            1,
            t("nw.stock.dividend.steps.step_1.title"),
            t("nw.stock.dividend.steps.step_1.description")
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{position: 'relative', paddingBottom: 4}}>
          {arrowBox(
            2,
            t("nw.stock.dividend.steps.step_2.title"),
            t("nw.stock.dividend.steps.step_2.description")
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={4} sx={{position: 'relative', paddingBottom: 4}}>
          {arrowBox(
            3,
            t("nw.stock.dividend.steps.step_3.title"),
            t("nw.stock.dividend.steps.step_3.description")
          )}
        </Grid>
      </Grid>
    </WhiteSection>

    <Section normalPage>
      <Cta>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.stock.dividend.chart.title")}</Typography>
        <Box sx={{
          marginTop: 5,
          display: {
            xs: 'none',
            md: 'block'
          }
        }}>
          <LineChart
            width={800}
            height={400}
            data={data}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} tickFormatter={(tick) => `${tick}.00%   `} />
            <Line type="linear" dataKey="div" stroke="#6FCADF" activeDot={{ r: 8 }} />
          </LineChart>
        </Box>
        <Box sx={{
          marginTop: 5,
          display: {
            xs: 'block',
            md: 'none'
          },
          marginLeft: {
            xs: -2,
            md: 0
          }
        }}>
          <LineChart
            width={300}
            height={200}
            data={data}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} tickFormatter={(tick) => `${tick}.00%   `} />
            <Line type="linear" dataKey="div" stroke="#6FCADF" activeDot={{ r: 8 }} />
          </LineChart>
        </Box>
        <Typography sx={{
          marginTop: 5,
          textAlign: 'center'
        }} variant="body2" html fuck_p_tags>{t("nw.stock.dividend.chart.text")}</Typography>

      </Cta>
    </Section>

    <Section>
      <Disclaimer />
    </Section>

    <Footer normalPage />
  </Page>;
}

