import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  Box,
} from '@mui/material';
import {
  Typography
} from '../styled';

type Props = {
  sx?: object;
  isSmall?: boolean;
  isInvest?: boolean;
  isIkDoeOokMee?: boolean;
  isAandelenKopen?: boolean;
};

export const Disclaimer: FunctionComponent<Props> = ({sx, isSmall, isInvest, isIkDoeOokMee, isAandelenKopen}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  return (isSmall ? <Typography sx={{
    marginTop: 1,
    fontSize: '0.8rem',
    lineHeight: '18px'
  }} variant="body1" html fuck_p_tags>{t(isInvest ? "nw.jumbo.disclaimer_short_invest" : (isIkDoeOokMee ? "nw.jumbo.disclaimer_short_doemee" : (isAandelenKopen ? "nw.jumbo.disclaimer_short_aandelenkopen" : "nw.jumbo.disclaimer_short")))}</Typography> : <Box sx={{
    padding: 4,
    border: '2px solid transparent',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px'
  }}>
    <Typography variant="subtitle1" html fuck_p_tags sx={{
      textAlign: 'center',
      lineHeight: '18px'
    }}>{t("nw.disclaimer_long_title")}</Typography>
    <Typography variant="body1" html fuck_p_tags sx={{
      textAlign: 'center',
      lineHeight: '18px',
      marginTop: 2
    }}>{t("nw.disclaimer_long")}</Typography>
  </Box>);
}